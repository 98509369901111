<template>
  <figure v-if="caption" class="prose prose-lg flex flex-col place-items-center gap-2 dark:prose-invert">
    <nuxt-img
      v-if="blok.filename"
      :src="blok.filename"
      format="webp,png"
      densities="x1 x2"
      :title="$attrs.title || blok.title"
      :provider="blok.filename.includes('a.storyblok') ? 'storyblok' : undefined"
      :width="width"
      :height="height"
      :fit="fitInside ? 'in' : undefined"
      :loading="loading"
      :decorative="decorative"
      :alt="decorative ? '' : blok.alt"
      :class="`${fitInside ? 'object-contain' : 'object-cover'} ${$props.class}`"
      :sizes="isNaN(+width) ? '390px sm:640px lg:1024px' : `${Math.min(+width, 390)}px sm:${Math.min(+width, 640)}px lg:${width}px`"
      v-bind="$attrs"
    />
    <figcaption v-if="caption" class="block w-auto text-center text-secondary empty:hidden dark:text-secondary-light">
      {{ caption }}
    </figcaption>
  </figure>
  <nuxt-img
    v-else
    :src="blok.filename"
    format="webp,png"
    densities="x1 x2"
    :provider="blok.filename.includes('a.storyblok') ? 'storyblok' : undefined"
    :title="$attrs.title || blok.title"
    :width="width"
    :height="height"
    :fit="fitInside ? 'in' : undefined"
    :loading="loading"
    :alt="decorative ? '' : blok.alt"
    :class="[fitInside ? 'object-contain' : 'object-cover', props.class]"
    :sizes="isNaN(+width) ? '390px sm:640px lg:1024px' : `${Math.min(+width, 390)}px sm:${Math.min(+width, 640)}px lg:${width}px`"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import type { AssetStoryblok } from "~/component-types-sb"

defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<{
    blok: AssetStoryblok
    width?: string | number
    height?: string | number
    fitInside?: boolean
    decorative?: boolean
    caption?: string
    loading?: "lazy" | "eager"
    class: string | string[] | Record<string, boolean>
  }>(),
  {
    width: "400",
    height: "400",
    fitInside: false,
    decorative: false,
    caption: "",
    loading: "lazy",
    class: "",
    fractionOfScreen: undefined,
  },
)
</script>
